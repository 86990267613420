import React, { useState } from "react"

import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"

import { Search } from "react-bootstrap-icons"
import CircularAccordion from "../../components/circularAccordion"
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"

const FaqBanner = ({ cms, generalQueries, technicalQueries }) => {
  const intl = useIntl()
  const [filtredFaqs, setFiltredFaqs] = useState([])
  const [askedQuery, setAskedQuery] = useState(null)
  const [inputedQuery, setInputedQuery] = useState(null)
  const [query, setQuery] = useState(null)

  const [generalFaqs, setGeneralFaqs] = useState(
    generalQueries.map(q => {
      return { title: q.node.question, content: q.node.answer }
    })
  )
  const [technologyFaqs, setTechnologyFaqs] = useState(
    technicalQueries.map(q => {
      return { title: q.node.question, content: q.node.answer }
    })
  )

  const handleOnSerchClick = () => {
    let query = inputedQuery

    let filtred = []

    if (query && query != "") {
      filtred = generalFaqs.concat(technologyFaqs).filter(faq => {
        return faq.title.toLowerCase().includes(query.toLowerCase())
      })
    }
    setAskedQuery(query)
    setFiltredFaqs(filtred)
  }

  const handleOnSearchInputChange = event => {
    let query = event.target.value
    setInputedQuery(query)

    if (query === "") {
      setAskedQuery(null)
      setFiltredFaqs([])
    }
  }

  let faqSections = null

  if (askedQuery) {
    faqSections = (
      <section className="searched-faq-section">
        <Box my={5}>
          <h2>
            <FormattedMessage
              id="faqBanner_resultFound"
              defaultMessage="Pytania znalezione"
            />
          </h2>
        </Box>
        {filtredFaqs.length > 0 ? (
          <CircularAccordion data={filtredFaqs}></CircularAccordion>
        ) : (
          <p>
            <FormattedMessage
              id="faqBanner_noResults"
              defaultMessage="Brak wyników dla zadanego wyszukiwania"
            />
          </p>
        )}
      </section>
    )
  } else {
    faqSections = (
      <>
        <section className="general-faq-section">
          <Box my={5}>
            <h2>
              <FormattedMessage
                id="faqBanner_generalFaqTitle"
                defaultMessage="Pytania ogólne"
              />
            </h2>
          </Box>
          <CircularAccordion data={generalFaqs}></CircularAccordion>
        </section>
        <section className="technical-faq-section">
          <Box my={5}>
            <h2>
              <FormattedMessage
                id="faqBanner_technicalFaqTitle"
                defaultMessage="Pytania techniczne"
              />
            </h2>
          </Box>
          <CircularAccordion data={technologyFaqs}></CircularAccordion>
        </section>
      </>
    )
  }

  return (
    <div className="faq-banner">
      <Container>
        <Box className="faq-header" display="flex" flexDirection="column">
          <h1>{cms.title}</h1>
          <p>{cms.description}</p>
          <Card className="search-box-card">
            <CardContent>
              <Grid
                container
                wrap="wrap"
                alignItems="center"
                spacing={3}
                fullWidth
              >
                <Grid item xs={12} md>
                  <TextField
                    label={intl.formatMessage({
                      id: "searchForm_lblFieldSearch",
                      defaultMessage: "Czego dotyczy Twoje pytanie?",
                    })}
                    type="search"
                    value={query}
                    onChange={handleOnSearchInputChange}
                    className="form-control-borderless"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <Button
                    type="button"
                    className="search-button"
                    onClick={handleOnSerchClick}
                    fullWidth
                  >
                    <Search type="bold"></Search>
                    <FormattedMessage
                      id="searchForm_btnSubmit"
                      defaultMessage="SZUKAJ"
                    />
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box className="faq-body" my={3}>
          <Card className="faq-questions-card">
            <CardContent>{faqSections}</CardContent>
          </Card>
        </Box>
      </Container>
    </div>
  )
}

export default FaqBanner
