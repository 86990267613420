import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FaqBanner from "../sections/faq/faq-banner"
import { graphql } from "gatsby"

import { useIntl } from "gatsby-plugin-react-intl"

const FaqPage = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={data.strapiFaq.seo.title}
        description={data.strapiFaq.seo.description}
        keywords={data.strapiFaq.seo.keywords}
      />
      <FaqBanner
        generalQueries={data.allStrapiGeneralFaq.edges}
        technicalQueries={data.allStrapiTechnologyFaq.edges}
        cms={data.strapiFaq}
      ></FaqBanner>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiFaq {
      seo {
        title
        description
        keywords {
          tag
        }
      }
      title
      description
    }
    allStrapiTechnologyFaq {
      edges {
        node {
          question
          answer
        }
      }
    }
    allStrapiGeneralFaq {
      edges {
        node {
          question
          answer
        }
      }
    }
  }
`

export default FaqPage
